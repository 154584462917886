import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'threedsPayment';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        threedsPayment: null,
    };
}

export const threedsPayment = createAsyncThunk('threedsPayment', async (name, { rejectWithValue }) => {
    try {
        const response = await api.post('/api/payment/3dsecure', { name });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const threedsPaymentSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetThreedsPayment: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.threedsPayment = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(threedsPayment.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(threedsPayment.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.threedsPayment = action.payload;
            })
            .addCase(threedsPayment.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetThreedsPayment } = threedsPaymentSlice.actions;
export const threedsPaymentReducer = threedsPaymentSlice.reducer;
