import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TemplateFrame from "./TemplateFrame";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../store/package/getPackages.slice";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./theme/getCheckoutTheme";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from 'react-router-dom';

function PackageCard({ id, name, description, price, style }) {
  const theme = useTheme();
  const navigate = useNavigate();
  // const imageUrl = style
  //   ? JSON.parse(style)?.image
  //   : "https://via.placeholder.com/80";

  const handleContinue = () => {
    navigate(`/checkout?packageId=${id}`);
  };

  return (
    <Card
      sx={{
        display: "flex",
        mb: 2,
        bgcolor: "background.paper",
        height: 130,
        overflow: "hidden",
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 80, height: 100, objectFit: "cover" }}
        // image={imageUrl}
        alt={name}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 1,
          overflow: "hidden",
          minWidth: 0,
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="subtitle2" component="div" noWrap>
            {name}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: 1.2,
              height: "2.4em",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "auto",
          }}
        >
          <Typography variant="subtitle2" color="primary" noWrap>
            {price === 0 ? "Ücretsiz" : `${price} TL`}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="sm"
            sx={{ minWidth: "auto", px: 1, py: 0.5, fontSize: "0.75rem" }}
            onClick={handleContinue}
          >
            Devam Et
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

function Packages() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const dispatch = useDispatch();
  const getPackagesState = useSelector((state) => state.getPackages);
  const [packages, setPackages] = React.useState([]);
  const checkoutTheme = createTheme(getCheckoutTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const location = useLocation();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const themeParam = urlParams.get('theme');
    if (token) {
      localStorage.setItem("accessToken", token);
    }
    if (themeParam === 'light' || themeParam === 'dark') {
      setMode(themeParam);
      localStorage.setItem("themeMode", themeParam);
    } else {
      const savedMode = localStorage.getItem("themeMode");
      if (savedMode) {
        setMode(savedMode);
      } else {
        const systemPrefersDark = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        setMode(systemPrefersDark ? "dark" : "light");
      }
    }
  }, [location]);

  React.useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  React.useEffect(() => {
    if (getPackagesState.done) {
      setPackages(getPackagesState.getPackages?.result.response);
    }
  }, [getPackagesState.done]);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
    >
      <ThemeProvider theme={showCustomTheme ? checkoutTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <Box
          sx={{
            minHeight: "100dvh",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            backgroundColor: "background.default",
            px: 2,
            py: 3,
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            style={{ fontWeight: "bold", marginBottom: "20px" }}
            gutterBottom
            align="left"
            color="text.primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '0px', marginTop: '5px' }}
            >
              <g clip-path="url(#clip0_2648_1215)">
                <g filter="url(#filter0_d_2648_1215)">
                  <path
                    d="M25.0503 13H13C11.067 13 9.5 14.567 9.5 16.5V28.5503C9.5 31.6684 13.27 33.23 15.4749 31.0251L27.5251 18.9749C29.73 16.77 28.1684 13 25.0503 13Z"
                    fill="white"
                  />
                  <path
                    d="M25.0503 13H13C11.067 13 9.5 14.567 9.5 16.5V28.5503C9.5 31.6684 13.27 33.23 15.4749 31.0251L27.5251 18.9749C29.73 16.77 28.1684 13 25.0503 13Z"
                    fill="url(#paint0_linear_2648_1215)"
                  />
                </g>
                <g filter="url(#filter1_d_2648_1215)">
                  <path
                    d="M25.0503 4H13C11.067 4 9.5 5.567 9.5 7.5V19.5503C9.5 22.6684 13.27 24.23 15.4749 22.0251L27.5251 9.97487C29.73 7.77 28.1684 4 25.0503 4Z"
                    fill="white"
                  />
                  <path
                    d="M25.0503 4H13C11.067 4 9.5 5.567 9.5 7.5V19.5503C9.5 22.6684 13.27 24.23 15.4749 22.0251L27.5251 9.97487C29.73 7.77 28.1684 4 25.0503 4Z"
                    fill="url(#paint1_linear_2648_1215)"
                    fill-opacity="0.24"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_2648_1215"
                  x="-4.5"
                  y="-1"
                  width="51.0574"
                  height="51.0573"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="8" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2648_1215"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2648_1215"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_2648_1215"
                  x="-0.5"
                  y="-6"
                  width="43.0574"
                  height="43.0573"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="6" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2648_1215"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2648_1215"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_2648_1215"
                  x1="47.788"
                  y1="-35.8455"
                  x2="-9.94099"
                  y2="8.62934"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="0.867732" stop-color="#7D56F2" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2648_1215"
                  x1="47.788"
                  y1="-45.979"
                  x2="-11.6251"
                  y2="-1.98292"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="0.867732" stop-color="#7D56F2" />
                </linearGradient>
                <clipPath id="clip0_2648_1215">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>'Shot Paketleri</span>
          </Typography>
          {packages?.map((pkg) => (
            <PackageCard key={pkg.id} {...pkg} />
          ))}
        </Box>
      </ThemeProvider>
    </TemplateFrame>
  );
}

export default Packages;
