import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "updateUser";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    updateUser: null,
  };
}

export const updateUser = createAsyncThunk("updateUser", async (data, { rejectWithValue }) => {
  try 
  {
    const response = await api.post("/api/user/update", data);
    return response.data; 
  } 
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const updateUserSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetUpdateUser: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.updateUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.updateUser = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetUpdateUser } = updateUserSlice.actions;
export const updateUserReducer = updateUserSlice.reducer;
