import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import './App.css';
import routes from './routes';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite/styles/index.less';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
function App() {

    return useRoutes(routes);
}

export default App;
