import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 72, width: 72 }}>
      <svg
        width="72"
        height="72"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2648_1215)">
          <g filter="url(#filter0_d_2648_1215)">
            <path
              d="M25.0503 13H13C11.067 13 9.5 14.567 9.5 16.5V28.5503C9.5 31.6684 13.27 33.23 15.4749 31.0251L27.5251 18.9749C29.73 16.77 28.1684 13 25.0503 13Z"
              fill="white"
            />
            <path
              d="M25.0503 13H13C11.067 13 9.5 14.567 9.5 16.5V28.5503C9.5 31.6684 13.27 33.23 15.4749 31.0251L27.5251 18.9749C29.73 16.77 28.1684 13 25.0503 13Z"
              fill="url(#paint0_linear_2648_1215)"
            />
          </g>
          <g filter="url(#filter1_d_2648_1215)">
            <path
              d="M25.0503 4H13C11.067 4 9.5 5.567 9.5 7.5V19.5503C9.5 22.6684 13.27 24.23 15.4749 22.0251L27.5251 9.97487C29.73 7.77 28.1684 4 25.0503 4Z"
              fill="white"
            />
            <path
              d="M25.0503 4H13C11.067 4 9.5 5.567 9.5 7.5V19.5503C9.5 22.6684 13.27 24.23 15.4749 22.0251L27.5251 9.97487C29.73 7.77 28.1684 4 25.0503 4Z"
              fill="url(#paint1_linear_2648_1215)"
              fill-opacity="0.24"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2648_1215"
            x="-4.5"
            y="-1"
            width="51.0574"
            height="51.0573"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="8" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2648_1215"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2648_1215"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_2648_1215"
            x="-0.5"
            y="-6"
            width="43.0574"
            height="43.0573"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2648_1215"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2648_1215"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2648_1215"
            x1="47.788"
            y1="-35.8455"
            x2="-9.94099"
            y2="8.62934"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.867732" stop-color="#7D56F2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2648_1215"
            x1="47.788"
            y1="-45.979"
            x2="-11.6251"
            y2="-1.98292"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.867732" stop-color="#7D56F2" />
          </linearGradient>
          <clipPath id="clip0_2648_1215">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
