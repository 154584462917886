import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "buyPackage";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    buyPackage: null,
  };
}

export const complete3dSecure = createAsyncThunk("complete3dSecure", async ({ packageId, userId }, { rejectWithValue }) => {
  try 
  {
    const response = await api.get(`/api/purchase/complete-3d-secure?packageId=${packageId}&userId=${userId}`);
    return response.data; 
  } 
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const complete3dSecureSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetComplete3dSecure: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.buyPackage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(complete3dSecure.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(complete3dSecure.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.buyPackage = action.payload;
      })
      .addCase(complete3dSecure.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetComplete3dSecure } = complete3dSecureSlice.actions;
export const complete3dSecureReducer = complete3dSecureSlice.reducer;