import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const name = "getTurkey";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getTurkey: null,
  };
}

export const getTurkey = createAsyncThunk(
  'turkey/getData',
  async () => {
    const response = await axios.get("https://turkiyeapi.dev/api/v1/provinces");
    return response.data;
  }
);

const getTurkeySlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetgetTurkey: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getTurkey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTurkey.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getTurkey.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getTurkey = action.payload;
      })
      .addCase(getTurkey.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetgetTurkey } = getTurkeySlice.actions;
export const getTurkeyReducer = getTurkeySlice.reducer;
