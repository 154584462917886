import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "buyPackage";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    buyPackage: null,
  };
}

export const buyPackage = createAsyncThunk("buyPackage", async (data, { rejectWithValue }) => {
  try 
  {
    const response = await api.post("/api/purchase/buy-package", data);
    return response.data; 
  } 
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const buyPackageSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetBuyPackage: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.buyPackage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyPackage.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(buyPackage.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.buyPackage = action.payload;
      })
      .addCase(buyPackage.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetBuyPackage } = buyPackageSlice.actions;
export const buyPackageReducer = buyPackageSlice.reducer;
