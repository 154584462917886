import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import Checkout from "./pages/Checkout";
import Packages from "./pages/Packages";
import Callback from "./pages/Callback";

const routes = [
  {
    path: "/",
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/packages",
    element: <Packages />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
];

export default routes;
