import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store.js";
import "rsuite/dist/rsuite.min.css";
import { CustomProvider } from "rsuite";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter basename="/">
    <CustomProvider theme="light">
      <Provider store={store}>
        <App />
      </Provider>
    </CustomProvider>
  </BrowserRouter>
);

reportWebVitals();
