import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import AddressForm from "./components/checkout/AddressForm";
import getCheckoutTheme from "./theme/getCheckoutTheme";
import Info from "./components/checkout/Info";
import InfoMobile from "./components/checkout/InfoMobile";
import PaymentForm from "./components/checkout/PaymentForm";
import Review from "./components/checkout/Review";
import SitemarkIcon from "./components/checkout/SitemarkIcon";
import TemplateFrame from "./TemplateFrame";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../store/user/getUser.slice";
import { updateUser } from "../store/user/updateUser.slice";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { buyPackage } from "../store/payment/buy-package.slice";
import { useState, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

export default function Checkout() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const checkoutTheme = createTheme(getCheckoutTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [activeStep, setActiveStep] = React.useState(0);
  const [user, setUser] = React.useState(null);
  const [formData, setFormData] = React.useState({});
  const [paymentData, setPaymentData] = React.useState({});

  const [packageId, setPackageId] = React.useState(null);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.getUser);
  const updateUserState = useSelector((state) => state.updateUser);
  const [showThreeDSecure, setShowThreeDSecure] = useState(false);
  const [threeDSecureContent, setThreeDSecureContent] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const packageId = urlParams.get("packageId");
    if (token) {
      localStorage.setItem("accessToken", token);
    }
    if (packageId) {
      localStorage.setItem("packageId", packageId);
      setPackageId(packageId);
    }
  }, []);

  React.useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  React.useEffect(() => {
    if (userState.done) {
      const userData = userState.getUser.result.detail;
      localStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);

      // Otomatik olarak ikinci adıma geçme kontrolü
      if (
        userData.name &&
        userData.surname &&
        userData.address &&
        userData.city &&
        userData.country &&
        userData.email &&
        userData.identityNumber
      ) {
        setActiveStep(1);
      }
    }
  }, [userState]);

  React.useEffect(() => {
    if (updateUserState.done) {
      setActiveStep(1);
    }
  }, [updateUserState]);

  React.useEffect(() => {
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };
  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Satın al butonuna tıklandığında
      dispatch(buyPackage(paymentData));
      // Burada satın alma işlemini gerçekleştirecek fonksiyonu çağırabilirsiniz
      // Örneğin: handlePurchase();
    } else if (activeStep === 0) {
      if (
        formData.name &&
        formData.surname &&
        formData.address &&
        formData.email &&
        formData.identityNumber &&
        formData.city &&
        formData.country
      ) {
        dispatch(updateUser(formData));
      } else {
        Swal.fire({
          icon: "warning",
          title: "Eksik bilgiler var!",
          text: "Lütfen tüm alanları doldurunuz!",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleFormChange = React.useCallback((newData) => {
    setFormData(newData);
  }, []);

  const handlePaymentDataChange = useCallback((data) => {
    setPaymentData(data);
  }, []);

  const steps = ["Adresiniz", "Ödeme detayları", "Siparişinizi onaylayın"];
  function getStepContent(step, user, onFormChange) {
    switch (step) {
      case 0:
        return <AddressForm user={user} onFormChange={onFormChange} />;
      case 1:
        return <PaymentForm onPaymentDataChange={handlePaymentDataChange} />;
      case 2:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  }

  const handlePurchase = useCallback(() => {
    const purchaseData = {
      packageId: packageId,
      paymentCard: {
        cardHolderName: paymentData.cardHolderName,
        cardNumber: paymentData.cardNumber,
        expireMonth: paymentData.expireMonth,
        expireYear: paymentData.expireYear,
        cvc: paymentData.cvv,
      },
    };
    console.log(purchaseData);
    if (
      !purchaseData.paymentCard.cardHolderName ||
      !purchaseData.paymentCard.cardNumber ||
      !purchaseData.paymentCard.expireMonth ||
      !purchaseData.paymentCard.expireYear ||
      !purchaseData.paymentCard.cvc
    ) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Lütfen tüm alanları doldurun.",
      });
      return;
    }

    dispatch(buyPackage(purchaseData))
      .unwrap()
      .then((response) => {
        if (response.result && response.result.threeDSUrl) {
          handleThreeDSecure(response.result.threeDSUrl.threeDSHtmlContent);
        } else {
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Ödeme başarıyla tamamlandı!",
          });
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Ödeme işlemi başarısız:", error);
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.",
        });
      });
  }, [dispatch, packageId, paymentData, navigate]);

  const handleThreeDSecure = useCallback((base64HtmlContent) => {
    const decodedHtml = atob(base64HtmlContent);
    setThreeDSecureContent(decodedHtml);
    setShowThreeDSecure(true);
  }, []);

  const handleCloseThreeDSecure = useCallback(() => {
    setShowThreeDSecure(false);
  }, []);

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
    >
      <ThemeProvider theme={showCustomTheme ? checkoutTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <Grid container sx={{ height: { xs: "100%", sm: "100dvh" } }}>
          <Grid
            size={{ xs: 12, sm: 5, lg: 4 }}
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              backgroundColor: "background.paper",
              borderRight: { sm: "none", md: "1px solid" },
              borderColor: { sm: "none", md: "divider" },
              alignItems: "start",
              pt: 16,
              px: 10,
              gap: 4,
            }}
          >
            <SitemarkIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "100%",
                maxWidth: 500,
              }}
            >
              <Info totalPrice={activeStep >= 2 ? "$144.97" : "34.98₺"} />
            </Box>
          </Grid>
          <Grid
            size={{ sm: 12, md: 7, lg: 8 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
              width: "100%",
              backgroundColor: { xs: "transparent", sm: "background.default" },
              alignItems: "start",
              pt: { xs: 6, sm: 16 },
              px: { xs: 2, sm: 10 },
              gap: { xs: 4, md: 8 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { sm: "space-between", md: "flex-end" },
                alignItems: "center",
                width: "100%",
                maxWidth: { sm: "100%", md: 600 },
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  flexGrow: 1,
                }}
              >
                <Stepper
                  id="desktop-stepper"
                  activeStep={activeStep}
                  sx={{ width: "100%", height: 40 }}
                >
                  {steps.map((label) => (
                    <Step
                      sx={{
                        ":first-child": { pl: 0 },
                        ":last-child": { pr: 0 },
                      }}
                      key={label}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
            {/* <Card sx={{ display: { xs: "flex", md: "none" }, width: "100%" }}>
              <CardContent
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="subtitle2" gutterBottom>
                    Seçilen paket
                  </Typography>
                  <Typography variant="body1">
                    {activeStep >= 2 ? "$144.97" : "$134.98"}
                  </Typography>
                </div>
                <InfoMobile
                  totalPrice={activeStep >= 2 ? "$144.97" : "$134.98"}
                />
              </CardContent>
            </Card> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "100%",
                maxWidth: { sm: "100%", md: 600 },
                maxHeight: "720px",
                gap: { xs: 5, md: "none" },
              }}
            >
              <Stepper
                id="mobile-stepper"
                activeStep={activeStep}
                alternativeLabel
                sx={{ display: { sm: "flex", md: "none" } }}
              >
                {steps.map((label) => (
                  <Step
                    sx={{
                      ":first-child": { pl: 0 },
                      ":last-child": { pr: 0 },
                      "& .MuiStepConnector-root": { top: { xs: 6, sm: 12 } },
                    }}
                    key={label}
                  >
                    <StepLabel
                      sx={{
                        ".MuiStepLabel-labelContainer": { maxWidth: "70px" },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <Stack spacing={2} useFlexGap>
                  <Typography variant="h1">📦</Typography>
                  <Typography variant="h5">
                    Thank you for your order!
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    Your order number is
                    <strong>&nbsp;#140396</strong>. We have emailed your order
                    confirmation and will update you once its shipped.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      alignSelf: "start",
                      width: { xs: "100%", sm: "auto" },
                    }}
                  >
                    Go to my orders
                  </Button>
                </Stack>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep, user, handleFormChange)}
                  <Box
                    sx={[
                      {
                        display: "flex",
                        flexDirection: { xs: "column-reverse", sm: "row" },
                        alignItems: "end",
                        flexGrow: 1,
                        gap: 1,
                        pb: { xs: 12, sm: 0 },
                        mt: { xs: 2, sm: 0 },
                        mb: "60px",
                      },
                      activeStep !== 0
                        ? { justifyContent: "space-between" }
                        : { justifyContent: "flex-end" },
                    ]}
                  >
                    {activeStep !== 0 && (
                      <Button
                        startIcon={<ChevronLeftRoundedIcon />}
                        onClick={handleBack}
                        variant="text"
                        sx={{ display: { xs: "none", sm: "flex" } }}
                      >
                        Geri
                      </Button>
                    )}
                    {activeStep !== 0 && (
                      <Button
                        startIcon={<ChevronLeftRoundedIcon />}
                        onClick={handleBack}
                        variant="outlined"
                        fullWidth
                        sx={{ display: { xs: "flex", sm: "none" } }}
                      >
                        Geri
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      endIcon={
                        !updateUserState.spinner && <ChevronRightRoundedIcon />
                      }
                      onClick={
                        activeStep === steps.length - 1
                          ? handlePurchase
                          : handleNext
                      }
                      disabled={updateUserState.spinner}
                      sx={{
                        width: { xs: "100%", sm: "fit-content" },
                        position: "relative",
                      }}
                    >
                      {updateUserState.spinner ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      ) : activeStep === steps.length - 1 ? (
                        "Satın al"
                      ) : (
                        "Devam"
                      )}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Grid>
        </Grid>
        <Modal
          open={showThreeDSecure}
          onClose={handleCloseThreeDSecure}
          aria-labelledby="3d-secure-modal"
          aria-describedby="3d-secure-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: 600,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              maxHeight: "90vh",
              overflow: "auto",
            }}
          >
            <iframe
              srcDoc={threeDSecureContent}
              style={{ width: "100%", height: "500px", border: "none" }}
              title="3D Secure"
            />
          </Box>
        </Modal>
      </ThemeProvider>
    </TemplateFrame>
  );
}
