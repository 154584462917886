import axios from 'axios';
import { useDispatch } from 'react-redux';
import { API_BASE } from '../config/env';

const api = axios.create({
    baseURL: API_BASE,
    headers: {
        'Accept-Language': 'tr-tr',
        'Content-Type': 'application/json',
    },
});
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
      
        return Promise.reject(error);
    }
);
export default api;
