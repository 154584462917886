import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getTurkey } from '../../../store/general/getTurkey.slice';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function AddressForm({ user, onFormChange }) {
  const dispatch = useDispatch();
  const [cities, setCities] = React.useState([]);
  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [identityNumber, setIdentityNumber] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');
  const turkeyState = useSelector((state) => state.getTurkey);
  React.useEffect(() => {
    dispatch(getTurkey());
  }, [dispatch])

  React.useEffect(() => {
    if (turkeyState.done) {
      setCities(turkeyState.getTurkey.data);
    }
  }, [turkeyState.getTurkey]);

  React.useEffect(() => {
    setName(user?.name || '');
    setSurname(user?.surname || '');
    setAddress(user?.address || '');
    setEmail(user?.email || '');
    setIdentityNumber(user?.identityNumber || '');
    setCity(user?.city || '');
    setCountry(user?.country || '');
  }, [user])

  React.useEffect(() => {
    onFormChange({
      name: name,
      surname: surname,
      address: address,
      email: email,
      identityNumber: identityNumber,
      city: city,
      country: country,
    });
  }, [name, surname, address, email, identityNumber, city, country])

  

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onFormChange({ [name]: value });
  };

  return (
    <Grid container spacing={3}>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="name" required>
          Adınız
        </FormLabel>
        <OutlinedInput
          id="name"
          name="name"
          type="text"
          disabled={name.length > 0}
          placeholder="Adınız"
          autoComplete="given-name"
          required
          size="small"
          value={name}
          onChange={(e)=>{
            handleInputChange(e);
            setName(e.target.value);
          }}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="last-name" required>
          Soyadınız
        </FormLabel>
        <OutlinedInput
          id="last-name"
          name="last-name"
          type="last-name"
          disabled={surname.length > 0}
          placeholder="Soyadınız"
          autoComplete="soyadınız"
          required
          size="small"
          value={surname}
          onChange={(e)=>{
            handleInputChange(e);
            setSurname(e.target.value);
          }}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="address1" required>
          Adresiniz
        </FormLabel>
        <OutlinedInput
          id="address1"
          name="address1"
          type="address1"
          placeholder="Sokak adı ve numarası"
          autoComplete="adresiniz"
          required
          size="small"
          value={address}
          onChange={(e)=>{
            handleInputChange(e);
            setAddress(e.target.value);
          }}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="email" required>
          Email
        </FormLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          autoComplete="email"
          required
          size="small"
          value={email}
          onChange={(e)=>{
            handleInputChange(e);
            setEmail(e.target.value);
          }}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="identityNumber" required>
          TC Kimlik Numaranız
        </FormLabel>
        <OutlinedInput
          id="identityNumber"
          name="identityNumber"
          type="identityNumber"
          placeholder="TC Kimlik Numaranız"
          autoComplete="identityNumber"
          required
          size="small"
          value={identityNumber}
          onChange={(e)=>{
            handleInputChange(e);
            setIdentityNumber(e.target.value);
          }}
        />
      </FormGrid>
      <FormGrid size={{ xs: 6 }}>
        <FormLabel htmlFor="city" required>
          Şehir
        </FormLabel>
        <Select
          id="city"
          name="city"
          value={city}
          onChange={(e) => {
            handleCityChange(e);
            handleInputChange(e);
            setCity(e.target.value);
          }}
          input={<OutlinedInput />}
          size="small"
          required
        >
          <MenuItem value=""><em>Seçiniz</em></MenuItem>
          {cities.map((cityItem) => (
            <MenuItem key={cityItem.id} value={cityItem.name}>
              {cityItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormGrid>
      <FormGrid size={{ xs: 6 }}>
        <FormLabel htmlFor="country" required>
          Ülke
        </FormLabel>
        <Select
          id="country"
          name="country"
          value={country}
          onChange={(e)=>{
            handleCountryChange(e);
            handleInputChange(e);
            setCountry(e.target.value);
          }}
          input={<OutlinedInput />}
          size="small"
          required
        >
          <MenuItem value=""><em>Seçiniz</em></MenuItem>
          <MenuItem value="Turkey">Türkiye</MenuItem>
          <MenuItem value="England">İngiltere</MenuItem>
        </Select>
      </FormGrid>
    
      
    </Grid>
  );
}
