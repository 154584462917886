import { configureStore } from '@reduxjs/toolkit';
import { threedsPaymentReducer } from './payment/3dsecure';
import { getUserReducer } from './user/getUser.slice';
import { getTurkeyReducer } from './general/getTurkey.slice';
import { updateUserReducer } from './user/updateUser.slice';
import { getPackagesReducer } from './package/getPackages.slice';
import { buyPackageReducer } from './payment/buy-package.slice';
import { getPackageDetailReducer } from './package/getPackageDetail.slice';
import { complete3dSecureReducer } from './payment/complete-3d-secure.slice';
export const store = configureStore({
    reducer: {
        threedsPayment: threedsPaymentReducer,
        getUser: getUserReducer,
        getTurkey: getTurkeyReducer,
        updateUser: updateUserReducer,
        getPackages: getPackagesReducer,
        buyPackage: buyPackageReducer,
        getPackageDetail: getPackageDetailReducer,
        complete3dSecure: complete3dSecureReducer
    },
});

/*
import { createCodeReducer } from './auth/createCode.slice';
import { verifyCodeReducer } from './auth/verifyCode.slice';
import { addExamReducer } from './exam/addExam.slice';
import { getExamsReducer } from './exam/getExams.slice';
import { deleteExamReducer } from './exam/deleteExam.slice';

export const store = configureStore({
    reducer: {
        createCode: createCodeReducer,
        verifyCode: verifyCodeReducer,
        addExam: addExamReducer,
        getExams: getExamsReducer,
        deleteExam: deleteExamReducer

    },
});*/
