import * as React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/user/getUser.slice';
import { getPackageDetail } from '../../../store/package/getPackageDetail.slice';


export default function Review() {

  const dispatch = useDispatch();

  const [user, setUser] = React.useState(null);
  const [packageId, setPackageId] = React.useState(null);
  const [packageDetail, setPackageDetail] = React.useState(null);
  const packageDetailState = useSelector((state) => state.getPackageDetail);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get("packageId");
    setPackageId(packageId)
  }, []);


  React.useEffect(() => {
    dispatch(getPackageDetail(packageId))
  }, [dispatch, packageId])

  React.useEffect(() => {
    packageDetailState.done && setPackageDetail(packageDetailState?.getPackageDetail?.result?.response)
  }, [packageDetailState.done])

  React.useEffect(() => {
    const user = localStorage.getItem("user")

    setUser(JSON.parse(user))
  }, [])

  console.log(packageDetail)
  return (
    <Stack spacing={2}>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Paket Bilgileri" secondary={packageDetail?.description} />
          <Typography variant="body2">{packageDetail?.name}</Typography>
        </ListItem>
       
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Ödeyeceğiniz Tutar" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {packageDetail?.price} &nbsp;₺
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Kullanıcı bilgileri
          </Typography>
          <Typography gutterBottom>{user?.name} {user?.surname}</Typography>
          <Typography gutterBottom sx={{ color: 'text.secondary' }}>
            {user?.address}
          </Typography>
        </div>
      
      </Stack>
    </Stack>
  );
}