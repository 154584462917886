import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { complete3dSecure } from '../store/payment/complete-3d-secure.slice';
import { CircularProgress, Typography, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Callback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState('loading');

  const complete3dSecureState = useSelector((state) => state.complete3dSecure);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const packageId = searchParams.get('packageId');
    const userId = searchParams.get('userId');

    if (packageId && userId) {
      dispatch(complete3dSecure({ packageId, userId }));
    } else {
      setStatus('error');
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (complete3dSecureState.done) {
      setStatus('success');
    } else if (complete3dSecureState.error) {
      setStatus('error');
    }
  }, [complete3dSecureState]);

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 3,
        textAlign: 'center',
      }}
    >
      {status === 'loading' && (
        <>
          <CircularProgress size={60} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Ödeme işlemi tamamlanıyor...
          </Typography>
        </>
      )}

      {status === 'success' && (
        <>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60 }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Ödeme başarıyla tamamlandı!
          </Typography>
          <Button variant="contained" onClick={handleNavigate} sx={{ mt: 3 }}>
            Panele Git
          </Button>
        </>
      )}

      {status === 'error' && (
        <>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Ödeme işlemi sırasında bir hata oluştu.
          </Typography>
          <Button variant="contained" onClick={handleNavigate} sx={{ mt: 3 }}>
            Panele Dön
          </Button>
        </>
      )}
    </Box>
  );
};

export default Callback;
